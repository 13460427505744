$( document ).ready(function(){
  var swiper = new Swiper('.swiper-container', {
    speed: 1000,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
      clickable: true,
    },
  });
}) 
