document.addEventListener('DOMContentLoaded', function() {
    $('button.close').click(function() {
        $('#modalLanding').hide();
        $('.overlay-landing').hide();
        $("body").removeClass("modal-open");
    });
    
    $('#modal').click(function() {
        $("body").addClass("modal-open");
        $('.overlay-landing').show();
        $('#modalLanding').show();
    })

    if($(window).width() >= 768) {
        if(document.getElementById('llegadas')) {
            window.onscroll = function() {
                if (window.pageYOffset >= 900) {
                    document.getElementsByClassName('img-fixed')[0].style.transform = 'translateY(90%)';
                    document.getElementsByClassName('img-fixed')[0].style.backgroundSize = '100% 80%';
                    document.getElementsByClassName('img-fixed')[0].style.position = 'relative';
                } else {
                    document.getElementsByClassName('img-fixed')[0].style.transform = 'none';
                    document.getElementsByClassName('img-fixed')[0].style.backgroundSize = '78% 80%';
                    document.getElementsByClassName('img-fixed')[0].style.position = 'fixed';
                }
            };
        }
    }

    
    $(document).bind('keydown',function(e){
        if (e.which == 27) {
            if(document.body.classList.contains('modal-open')) {
                $('#modalLanding').hide();
                $('.overlay-landing').hide();
                $("body").removeClass("modal-open");
            }               
        }
    });
    
});
